import React,{useEffect, useState} from 'react'
import { Link/*, Script, ScriptStrategy*/, graphql } from "gatsby"
import axios from 'axios';
import Layout from "../components/layout"
import ThemeContext from "./../context/ThemeContext"
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import useWindowSize from './../hooks/useGatsbyWindowSize'
import { useMediaQuery } from 'react-responsive'
import "animate.css/animate.min.css";
import ScrollAnimation from 'react-animate-on-scroll';
import SvgEl from './../components/SvgEl'
import LOGO from './../svg/logos'


const CreditsPage = ({ data }) => {

  //console.log(data.photos.edges);
  const [jsonContent, setJsonContent] = useState('');
  const [isLoaded, setIsLoaded] = useState(false);
  const [isMobile, setIsMobile] = useState(false);


  const mobile = useMediaQuery({ query: `(max-width: 767px)` });
  const {width,height} = useWindowSize();
    var device = '';

  function loadHtml(url) {
  var _json = url;
  axios.get(_json)
.then(function (response) {


  device = response.data.device;

  var _jsonContent =  response.data.content;
  setJsonContent(_jsonContent);
  setIsLoaded(true);
});
}



function slideEffect(){



};



  useEffect(() => {
    setIsMobile(mobile);



     loadHtml('https://discover.lilleymansion.com/app/v1/page/17/credits');
  },[]);


  return (

    <ThemeContext.Consumer>
        {theme => (
    <div id="page">



    <div id="page-wrapper" className="pt-5">




    <div className="mt-5 mb-5 text-center p-2 m-2 pb-0">
    <ScrollAnimation animateIn="animate__animated animate__fadeInUp animate__delay-.3s">
    <h1 className="titling pt-5">THANK YOU TO ALL OUR COLLABORATORS</h1>
    </ScrollAnimation>
    </div>




  {/*
    <div className="imageFullWidth">
  { isMobile && data.photos.edges.length > 1 ?

      <GatsbyImage
       fluid={data.photos.edges[1].node.childImageSharp.fluid}
       alt={data.photos.edges[1].node.base.split("-").join(" ").split(".")[0]}
       image={getImage(data.photos.edges[1].node)}
       />
       :
       <GatsbyImage
        fluid={data.photos.edges[0].node.childImageSharp.fluid}
        alt={data.photos.edges[0].node.base.split("-").join(" ").split(".")[0]}
        image={getImage(data.photos.edges[0].node)}
        />
    }


    </div>
    */}
    <div
    style={{
      margin: `0`,
      padding: `20px`,
      color:`#111`
    }}
    >
    <ScrollAnimation animateIn="animate__animated animate__fadeIn animate__delay-0s">
    <div className="container pt-4 p-2 text-left" dangerouslySetInnerHTML={{ __html: jsonContent }} />
    </ScrollAnimation>
    </div>

    </div>
    </div>
  )}
      </ThemeContext.Consumer>

  )
}

CreditsPage.Layout = Layout

export default CreditsPage




export const query = graphql`
  {
    site {
      buildTime(formatString: "YYYY-MM-DD hh:mm a z")
    }

    photos: allFile(
          sort: { fields: base, order: ASC }
          filter: {relativeDirectory: {eq: "home"}}
        ) {
          edges {
            node {
              id
              base
              childImageSharp {
                gatsbyImageData(
                  placeholder: DOMINANT_COLOR
                  height: 1200
                  quality: 80
                  formats: AUTO
                  transformOptions: {fit: COVER, cropFocus: ATTENTION}

                )
                fluid {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
        }
  }
`
